import cls from 'clsx';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { H2, P } from '@/components/atoms/Typography/Typography';
import { AppStoreLinks } from '@/components/molecules/AppStoreLinks/AppStoreLinks';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { useData } from '@/lib/pageData/pageDataContext';
import styles from './HelpSection.module.scss';

interface Props {
  className?: string;
}

export const HelpSection = ({ className }: Props) => {
  const { t } = useTranslation(nameSpacesConfig.common);
  const { navLinks } = useData<'default'>();

  return (
    <section className={cls(styles.container, className)}>
      <div className={styles.left}>
        <H2 version="level3">{t('common:footer.helpSection.title')}</H2>
        <P>{t('common:footer.helpSection.text')}</P>
      </div>
      <div className={styles.right}>
        <AppStoreLinks
          className={styles.appStoreLinks}
          googlePlayUrl={navLinks.playStore.url}
          appStoreUrl={navLinks.appStore.url}
          googlePlayTitle={t('common:navLinks.playStore')}
          appStoreTitle={t('common:navLinks.appStore')}
        />
      </div>
    </section>
  );
};
